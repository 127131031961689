import service from "@/utils/require"
import { nanoid } from "nanoid"

/** 登录 */
export const loginWithOutCode = (params) => {
    return service.post("/loginWithoutCode", params)
}

/** 获取用户信息 */
export const getUserInfo = () => {
    return service.get("/getInfo", {})
}

/** 获取微信登录二维码 */
export const getWxQRCode = () => {
    return service.get("/wx/createPcLoginCode", {
        params: {
            scene: nanoid()
        }
    })
}

/** 轮询获取用户登录信息 */
export const getWxLoginState = (scene) => {
    return service.get("/wx/pcLoginState", {
        params: {
            scene
        }
    })
}

/* 邀请好友 */
export const userInvitationQr = () => {
    return service.get("/system/user/userInvitationQr", {})
}

/* 获取当前用户邀请码 */
export const userInvitationCode = () => {
    return service.get("/system/user/userInvitationCode", {})
}

/* 用户被邀请后进入系统登录完成后绑定邀请人 */
export const userInvite = (invitationCode) => {
    return service.post(`/system/user/userInvite/${invitationCode}`, {})
}

/* 邀请记录 */
export const userInviteList = (params) => service({
    url: '/system/user/userInviteList',
    method: 'get',
    params
})

/* 奖励记录 */
export const userInviteEarningList = (params) => service({
    url: '/userIntegral/userInviteEarningList',
    method: 'get',
    params
})
