<template>
  <div v-loading="loading">
    <canvas
      ref="myCanvas"
      width="500"
      height="500"
      @mousedown="startDragging"
      @mousemove="drag"
      @mouseup="stopDragging"
      @wheel="zoom"
    ></canvas>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    partSrc: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  watch: {
    value(newValue) {
      this.updateCanvas();
    },
  },
  data() {
    return {
      isDragging: false,
      isResizing: false,
      startX: 0,
      startY: 0,
      overlayX: 200,
      overlayY: 150,
      overlayWidth: 200,
      overlayHeight: 100, // 保持 2:1 的比例
      scaleX: 1,
      scaleY: 1,
      zoomFactor: 1,
      resizeHandleSize: 10,
      loading: false,
      isFlipped: false,
    };
  },
  mounted() {
    this.loading = true;
    this.updateCanvas();
  },
  methods: {
    drawCanvas(ctx, baseImage, overlayImage, drawHandles = true) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.drawImage(baseImage, 0, 0, ctx.canvas.width, ctx.canvas.height);

      ctx.save();
      ctx.translate(
        this.overlayX + this.overlayWidth / 2,
        this.overlayY + this.overlayHeight / 2
      );

      // 将角度转换为弧度
      const radian = (this.value * Math.PI) / 180;
      ctx.rotate(radian);

      if (this.isFlipped) {
        ctx.scale(-1, 1); // 水平翻转
      }

      ctx.scale(this.scaleX * this.zoomFactor, this.scaleY * this.zoomFactor);
      ctx.drawImage(
        overlayImage,
        -this.overlayWidth / 2,
        -this.overlayHeight / 2,
        this.overlayWidth,
        this.overlayHeight
      );
      ctx.restore();

      if (drawHandles) {
        // 绘制虚线边框和调整大小的手柄
        ctx.strokeStyle = "#000";
        ctx.lineWidth = 1;
        ctx.setLineDash([5, 5]); // 设置虚线样式
        ctx.strokeRect(
          this.overlayX,
          this.overlayY,
          this.overlayWidth,
          this.overlayHeight
        );
        ctx.fillStyle = "#000";

        // 添加四个角的手柄
        const handleSize = this.resizeHandleSize;
        ctx.fillRect(this.overlayX, this.overlayY, handleSize, handleSize);
        ctx.fillRect(
          this.overlayX + this.overlayWidth - handleSize,
          this.overlayY,
          handleSize,
          handleSize
        );
        ctx.fillRect(
          this.overlayX,
          this.overlayY + this.overlayHeight - handleSize,
          handleSize,
          handleSize
        );
        ctx.fillRect(
          this.overlayX + this.overlayWidth - handleSize,
          this.overlayY + this.overlayHeight - handleSize,
          handleSize,
          handleSize
        );
      }
    },
    startDragging(event) {
      const mouseX = event.offsetX;
      const mouseY = event.offsetY;

      if (this.isInResizeHandle(mouseX, mouseY)) {
        this.isResizing = true;
      } else {
        this.isDragging = true;
        this.startX = mouseX - this.overlayX;
        this.startY = mouseY - this.overlayY;
      }
    },
    drag(event) {
      if (this.isDragging) {
        this.overlayX = event.offsetX - this.startX;
        this.overlayY = event.offsetY - this.startY;
      } else if (this.isResizing) {
        this.overlayWidth = event.offsetX - this.overlayX;
        this.overlayHeight = this.overlayWidth / 1.2;
      }
      this.updateCanvas();
    },
    stopDragging() {
      this.isDragging = false;
      this.isResizing = false;
    },
    zoom(event) {
      event.preventDefault();
      if (event.deltaY < 0) {
        this.zoomFactor *= 1.1;
      } else {
        this.zoomFactor /= 1.1;
      }
      this.updateCanvas();
    },
    isInResizeHandle(mouseX, mouseY) {
      return (
        mouseX > this.overlayX + this.overlayWidth - this.resizeHandleSize &&
        mouseY > this.overlayY + this.overlayHeight - this.resizeHandleSize
      );
    },
    updateCanvas() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");
      const baseImage = new Image();
      baseImage.crossOrigin = "Anonymous"; //解决跨域图片问题
      const overlayImage = new Image();
      overlayImage.crossOrigin = "Anonymous"; //解决跨域图片问题

      baseImage.src = this.imgSrc;
      overlayImage.src = this.partSrc;

      overlayImage.onload = () => {
        this.loading = false;
        this.drawCanvas(ctx, baseImage, overlayImage);
      };
    },
    saveImage() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");
      const baseImage = new Image();
      const overlayImage = new Image();

      baseImage.src = this.imgSrc;
      overlayImage.src = this.partSrc;
      baseImage.crossOrigin = "Anonymous";
      overlayImage.crossOrigin = "Anonymous";

      overlayImage.onload = () => {
        // 临时绘制不带边框和手柄的图片
        this.drawCanvas(ctx, baseImage, overlayImage, false);
        canvas.toBlob((blob) => {
          const file = new File([blob], "canvas-image.png", {
            type: "image/png",
          });
          this.uploadImage(file);

          // 恢复带边框和手柄的图片
          this.drawCanvas(ctx, baseImage, overlayImage);
        });
      };
    },
    uploadImage(file) {
      const formData = new FormData();
      formData.append("file", file);

      // 使用 axios 或其他 HTTP 客户端上传文件
      axios
        .post("https://www.sheji-ai.cc/systemApi/files-anon", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$emit("update", response.data);
          this.$message.success("上传成功");
        })
        .catch((error) => {
          console.error("上传失败:", error);
        });
    },
    toggleFlip() {
      this.isFlipped = !this.isFlipped;
      this.updateCanvas();
    },
  },
};
</script>

<style scoped></style>
