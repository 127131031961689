<!-- 重新生成 -->
<template>
  <div>
    <layout leftContentName="参数设置" :mode="true">
      <template slot="firstTag">
        <div class="p-4 flex items-center justify-around">
          <div
            class="bg-[#F4F5F7] w-40 h-10 rounded-2xl leading-9 cursor-pointer border"
            :class="activeType === 'color' ? 'border-[#54C752]  ' : ''"
            @click="changeType('color')"
          >
            调色板
          </div>
          <div
            class="bg-[#F4F5F7] w-40 h-10 rounded-2xl leading-9 cursor-pointer border"
            :class="activeType === 'system' ? 'border-[#54C752] ' : ''"
            @click="changeType('system')"
          >
            材料库
          </div>
        </div>
        <div v-if="activeType === 'color'">
          <div class="flex w-full items-center justify-around px-1">
            <div class="w-[60px]">色相</div>
            <el-slider
              :min="0"
              :max="360"
              v-model="sexiang"
              @change="renderCanvas"
            ></el-slider>
            <div class="min-w-8 p-1 bg-[#F4F5F7]">{{ sexiang }}</div>
          </div>
          <div class="flex w-full items-center justify-around px-1 mt-2">
            <div class="w-[60px]">对比度</div>
            <el-slider
              :min="-100"
              :max="100"
              v-model="duibidu"
              @change="renderCanvas"
            ></el-slider>
            <div class="min-w-8 p-1 bg-[#F4F5F7]">{{ duibidu }}</div>
          </div>
          <div class="flex w-full items-center justify-around px-1 mt-2">
            <div class="w-[60px]">饱和度</div>
            <el-slider
              :min="-100"
              :max="100"
              v-model="baohedu"
              @change="renderCanvas"
            ></el-slider>
            <div class="min-w-8 p-1 bg-[#F4F5F7]">{{ baohedu }}</div>
          </div>
          <div class="flex w-full items-center justify-around px-1 mt-2">
            <div class="w-[60px]">色温</div>
            <el-slider
              :min="-100"
              :max="100"
              v-model="sewen"
              @change="renderCanvas"
            ></el-slider>
            <div class="min-w-8 p-1 bg-[#F4F5F7]">{{ sewen }}</div>
          </div>
          <div class="flex w-full items-center justify-around px-1 mt-2">
            <div class="w-[60px]">高光</div>
            <el-slider
              :min="-100"
              :max="100"
              v-model="gaoguang"
              @change="renderCanvas"
            ></el-slider>
            <div class="min-w-8 p-1 bg-[#F4F5F7]">{{ gaoguang }}</div>
          </div>
          <div class="flex w-full items-center justify-around px-1 mt-2">
            <div class="w-[60px]">阴影</div>
            <el-slider
              :min="-100"
              :max="100"
              v-model="yinying"
              @change="renderCanvas"
            ></el-slider>
            <div class="min-w-8 p-1 bg-[#F4F5F7]">{{ yinying }}</div>
          </div>
          <div class="flex w-full items-center justify-around px-1 mt-2">
            <div class="w-[60px]">亮度</div>
            <el-slider
              :min="-100"
              :max="100"
              v-model="liangdu"
              @change="renderCanvas"
            ></el-slider>
            <div class="min-w-8 p-1 bg-[#F4F5F7]">{{ liangdu }}</div>
          </div>
        </div>
        <div v-else-if="activeType === 'system'">
          <div class="flex px-4 mb-4 overflow-y-auto">
            <div
              class="mr-2 text-lg cursor-pointer"
              @click="changeColor('system')"
              :class="activeColor === 'system' ? 'activeClass' : ''"
            >
              材料广场
            </div>
            <div
              class="mr-2 text-lg cursor-pointer"
              @click="changeColor('mine')"
              :class="activeColor === 'mine' ? 'activeClass' : ''"
            >
              我的材料
            </div>
          </div>
          <div class="px-4 flex">
            <el-select v-model="activeMaterial" @change="getPartData">
              <el-option label="全部分类" value="all"></el-option>
              <el-option
                v-for="item in material"
                :key="item.id"
                :label="item.classifyName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="mt-4 px-4 grid grid-cols-3 gap-2">
            <div
              class="w-[120px] h-36 p-3 rounded cursor-pointer border flex flex-col justify-center items-center"
              @click="addPartVisible = true"
              v-if="activeColor === 'mine'"
            >
              <img src="@/assets/image.svg" class="w-12 h-12" alt="" />
              <div class="text-[#0033FF] text-sm mt-1">上传材质</div>
              <div class="text-[#878787] text-xs mt-1">
                建议图片像素1080P, 格式JPG/PNG, 大小不超过2MB
              </div>
            </div>
            <div
              class="w-[120px] h-36 p-3 rounded bg-[#F4F5F7] cursor-pointer border"
              :class="activePart?.id === item?.id ? 'border-[#54C752]' : ''"
              v-for="item in partData"
              @click="openPartDetail(item)"
              :key="item.id"
            >
              <div class="border h-24 rounded">
                <img
                  class="w-full h-20"
                  :src="
                    item.materialsSystemItems
                      ? item.materialsSystemItems[0].itemImgUrl
                      : item.materialsUserItems[0].itemImgUrl
                  "
                />
                <div
                  v-if="
                    item.materialsSystemItems?.length > 0 ||
                    item.materialsUserItems?.length > 0
                  "
                  class="relative left-7"
                >
                  <img src="@/assets/fourRound.svg" alt="" />
                </div>
              </div>
              <div class="mt-1 text-sm">{{ item.introduce }}</div>
            </div>
          </div>
          <div
            class="mt-2 w-9/10 bg-[#eFeFeF] mx-2 p-2 flex justify-center items-center cursor-pointer"
            @click="partSelectVisible = true"
          >
            <img src="@/assets/gengduo.svg" class="mr-2" alt="" />
            更多
          </div>
        </div>
      </template>
      <template slot="content">
        <div class="w-full h-full flex flex-col">
          <div
            class="flex justify-center items-center"
            style="height: calc(100% - 200px)"
          >
            <div class="w-[737px] h-[537px] flex">
              <div class="flex flex-col">
                <div v-for="(item, index) in paths" :key="index">
                  <canvas
                    :ref="'selectionCanvas' + index"
                    class="w-[100px] h-[100px] mt-2"
                  ></canvas>
                </div>
                <div
                  class="w-24 h-24 border bg-white rounded-xl mt-2 cursor-pointer flex flex-col justify-center items-center"
                  @click="areaVisiable = true"
                >
                  <img
                    src="@/assets/plus.svg"
                    class="w-8 h-8"
                    alt=""
                    srcset=""
                  />
                  <div class="text-[#E4E4E4] mt-2">新增选区</div>
                </div>
              </div>
              <div class="ml-4 mt-2 relative">
                <img
                  :src="detailedImg?.url || imgUrl"
                  ref="image"
                  class="w-[600px] h-[600px] rounded-xl"
                  crossorigin="anonymous"
                  v-show="paths.length === 0"
                />
                <canvas
                  ref="canvas"
                  class="w-[600px] h-[600px] rounded-xl"
                  v-show="paths.length > 0"
                ></canvas>
              </div>
            </div>
          </div>
          <div
            class="absolute bottom-0 h-28 w-full flex justify-center"
            :class="JSON.stringify(result) === '{}' ? 'h-28' : 'h-52'"
          >
            <div
              class="w-[650px] h-14 bg-white rounded-3xl flex justify-center items-center"
              @click="save"
            >
              <div
                class="w-[600px] rounded-3xl h-10 bg-[#54C752] text-white relative flex justify-center items-center cursor-pointer"
                :class="
                  paths.length === 0 && '!bg-[#D8D8D8] !cursor-not-allowed'
                "
              >
                <i class="el-icon-loading mr-4" v-if="saveLoading"></i>
                <div class="text-lg font-bold">保存</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <shejiDialog v-model="partDialogVisiable" width="400px">
      <div>
        <img
          class="w-full h-80"
          v-if="JSON.stringify(clickPart) !== '{}'"
          :src="
            clickPart?.materialsSystemItems?.length > 0
              ? clickPart?.materialsSystemItems[this.activePartIndex]
                  ?.itemImgUrl
              : clickPart?.materialsUserItems[this.activePartIndex]?.itemImgUrl
          "
          alt=""
        />
        <div class="flex justify-center mt-2">
          <div
            class="flex justify-start"
            v-if="JSON.stringify(clickPart) !== '{}'"
          >
            <template v-if="clickPart?.materialsSystemItems?.length > 0">
              <img
                v-for="(item, index) in clickPart?.materialsSystemItems"
                :key="item.id"
                :src="item.itemImgUrl"
                class="w-16 h-16 mr-2 cursor-pointer"
                :class="
                  index == activePartIndex ? 'border border-[#54C752]' : ''
                "
                @click="changImage(index, 'system')"
              />
            </template>
            <template v-else>
              <img
                v-for="(item, index) in clickPart?.materialsUserItems"
                :key="item.id"
                :src="item.itemImgUrl"
                class="w-16 h-16 mr-2 cursor-pointer"
                :class="
                  index == activePartIndex ? 'border border-[#54C752]' : ''
                "
                @click="changImage(index, 'mine')"
              />
            </template>
          </div>
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">材料编号：</span>
          {{ clickPart?.materialsNo }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">材料材质：</span>
          {{ clickPart?.materialsTextureName }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">材料尺码：</span>
          {{ clickPart?.materialsNo }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">供应商：</span>
          {{ clickPart?.providerName }}
        </div>
        <div class="mt-2 ml-2 flex">
          <span class="text-[#878787]">产品介绍：</span>
          {{ clickPart?.introduce }}
        </div>
      </div>
    </shejiDialog>

    <materialSelect
      @selected="changeActivePart"
      v-model="partSelectVisible"
    ></materialSelect>
    <addPart
      v-model="addPartVisible"
      @refresh="
        // getCategory()
        getPartData()
        getMaterial()
      "
    ></addPart>
    <colorArea
      @confirm="changePaths"
      v-model="areaVisiable"
      :imageSrc="imgUrl"
    ></colorArea>
  </div>
</template>

    <script>
import {
  getMaterialListCL,
  getSystemColorsPage,
  getUserGeneratorCoupon,
  getUserColorsPage,
  saveColors
} from '@/api/workbench'
import coverImage from '../components/coverImage'
import shejiDialog from '@/components/shejiDialog'
import layout from '../components/layout'
import SortHeader from '@/components/sortHeader'
import materialSelect from '../components/materialSelect'
import addPart from '../components/addPart'
import colorArea from '../components/colorArea'
import { nextTick } from 'vue'
export default {
  components: {
    coverImage,
    layout,
    shejiDialog,
    SortHeader,
    materialSelect,
    addPart,
    colorArea
  },
  computed: {
    id: function () {
      return this.$route.params?.id
    },
    itemId: function () {
      return this.$route.params?.itemid
    },
    index: function () {
      return this.$route.params?.index
    },
    imgUrl: function () {
      return this.$route.query?.imgUrl
    },
    type: function () {
      return this.$route.query?.type
    }
  },
  data() {
    return {
      result: {},
      cropImage: '',
      jobId: '',
      dialogVisiable: false,
      progress: 0,
      progressInterval: null,
      timer: null,
      allImage: '',
      couponInfo: null,
      material: [],
      activeType: 'color',
      activeMaterial: 'all',
      partData: [],
      clickPart: {},
      activePart: {},
      activePartImage: '',
      activePartIndex: 0,
      partDialogVisiable: false,
      classes: [],
      activeColor: 'system',
      activeClasses: {},
      rotate: 0,
      detailedImg: {},
      saveLoading: false,
      partSelectVisible: false,
      addPartVisible: false,
      areaVisiable: false,
      paths: [],
      sexiang: 0,
      duibidu: 100,
      baohedu: 0,
      sewen: 0,
      sediao: 0,
      gaoguang: 0,
      yinying: 0,
      liangdu: 0,
      canvasScale: 0.5
    }
  },
  watch: {
    isWait: function (val) {
      if (val) {
        this.progress = 0
        this.progressInterval = setInterval(() => {
          this.progress += 1
          if (this.progress >= 99) {
            clearInterval(this.progressInterval)
          }
        }, 1000)
      } else {
        clearInterval(this.progressInterval)
      }
    },
    activePartImage: function (val) {
      this.renderCanvas()
    }
  },
  methods: {
    closeDialog() {
      this.$refs.coverImage.saveImage()
    },
    checkFile(file) {
      const isFormat =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 200

      if (!isFormat) {
        this.$message.error('上传的图片只能是 PNG、JPG、JPEG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传的图片大小不能超过 200MB!')
      }
      const result = isFormat && isLt2M
      if (result) {
        this.fileLoading = true
        return true
      }
      return false
    },
    // 处理图片上传
    handleFileUpload(val) {
      this.$message.success('上传成功')
      this.images = val
      this.fileLoading = false
    },
    // 清除图片
    deleteImages(e) {
      this.images.splice(e, 1)
    },
    handleUpdate(img) {
      this.detailedImg = img
      console.log(this.detailedImg)
      this.dialogVisiable = false
    },
    changeColor(color) {
      this.activeColor = color
      this.getPartData()
    },
    // 保存
    async save() {
      if (this.paths.length === 0) {
        return
      }
      this.saveLoading = true
      this.$refs.canvas.toBlob((blob) => {
        const file = new File([blob], 'canvas-image.png', {
          type: 'image/png'
        })
        this.uploadImage(file)
      })
    },
    uploadImage(file) {
      const formData = new FormData()
      formData.append('file', file)

      // 使用 axios 或其他 HTTP 客户端上传文件
      axios
        .post('https://www.sheji-ai.cc/systemApi/files-anon', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(async (response) => {
          const result = await saveColors({
            jobId: this.id,
            jobItemId: this.itemId,
            imgUrl: response.data.compressFileUrl,
            originImgUrl: response.data.url
          })
          if (result) {
            this.$message.success('保存成功!')
            this.$router.push(`/workbench/${this.type}?id=${this.id}`)
          }
        })
        .catch((error) => {
          console.error('上传失败:', error)
        })
      this.saveLoading = false
    },

    // 获取当前用户的权益信息
    async getCoupon() {
      const result = await getUserGeneratorCoupon({
        isFast: String(1),
        taskType: 1
      })
      if (result) {
        this.couponInfo = result.data
      }
    },
    // // 获取分类
    // async getCategory() {
    //   const result = await getUserAllPartCategory()
    //   if (result) {
    //     this.classes = result.data
    //     this.classes.unshift({
    //       id: 0,
    //       classifyName: '全部'
    //     })
    //     this.activeClasses = this.classes[0] ? this.classes[0] : {}
    //   }
    // },
    // 获取用户可选择的配件类别列表
    async getMaterial() {
      const result = await getMaterialListCL()
      if (result) {
        this.material = result.data
      }
    },
    changeType(name, first = true) {
      this.activeType = name
      if (name !== 'color') {
        this.getPartData()
      }
    },
    changeClasses(val) {
      this.activeClasses = val
      this.getPartData()
    },
    /** 获取系统库/我的库数据 */
    async getPartData() {
      const params = {
        classifyId:
          this.activeMaterial === 'all' ? undefined : this.activeMaterial,
        pageNum: 1,
        pageSize: 12
      }
      const result =
        this.activeColor === 'system'
          ? await getSystemColorsPage(params)
          : await getUserColorsPage(params)
      if (result) {
        this.partData = result.rows
      }
    },
    // 打开配件具体选择页面
    openPartDetail(item) {
      this.partDialogVisiable = true
      this.clickPart = item
      this.activePart = item
      this.activePartImage =
        item.materialsSystemItems?.length > 0
          ? item.materialsSystemItems[0].itemImgUrl
          : item.materialsUserItems[0].itemImgUrl
    },
    openImageDialog() {
      if (this.imgUrl && this.activePartImage) {
        this.dialogVisiable = true
        return
      }
      this.$message.error('请先选择素材')
    },
    changImage(index, type) {
      this.activePartIndex = index
      this.activePartImage =
        type === 'system'
          ? this.activePart.materialsSystemItems[index].itemImgUrl
          : this.activePart.materialsUserItems[index].itemImgUrl
    },
    filp() {
      this.$refs.coverImage.toggleFlip()
    },
    changeActivePart(val) {
      this.activePart = val.activePart
      this.activePartImage = val.activePartImage
    },
    changePaths(path) {
      this.paths = path
      this.initializeCanvas()
      this.renderSelectionPanels()
    },
    renderSelectionPanels() {
      nextTick(() => {
        this.paths.forEach((path, index) => {
          const canvas = this.$refs[`selectionCanvas${index}`][0]
          const ctx = canvas.getContext('2d')

          const panelWidth = 100
          const panelHeight = 100

          canvas.width = panelWidth
          canvas.height = panelHeight

          // 黑色背景
          ctx.fillStyle = 'black'
          ctx.fillRect(0, 0, panelWidth, panelHeight)

          // 白色绘制选区
          ctx.strokeStyle = 'white'
          ctx.lineWidth = 2

          const scaleX = panelWidth / 600
          const scaleY = panelHeight / 600

          ctx.beginPath()
          ctx.moveTo(path[0].x * scaleX, path[0].y * scaleY)
          path.forEach((point) => {
            ctx.lineTo(point.x * scaleX, point.y * scaleY)
          })
          ctx.closePath()
          ctx.stroke()
        })
      })
    },
    initializeCanvas() {
      const canvas = this.$refs.canvas
      const image = this.$refs.image
      const ctx = canvas.getContext('2d')

      // 获取图片的真实宽高
      const imageWidth = image.naturalWidth
      const imageHeight = image.naturalHeight

      // 根据图片的真实宽高调整canvas尺寸
      const maxCanvasWidth = 800 // 可以根据需要调整最大宽度
      const scale = maxCanvasWidth / imageWidth // 计算缩放比例
      this.canvasScale = scale

      // 设置canvas的宽高
      canvas.width = 600
      canvas.height = 600

      this.ctx = ctx
      this.renderCanvas()
    },
    renderCanvas() {
      if (this.paths.length === 0) {
        this.$message.error('请先添加选区')
        return
      }
      const canvas = this.$refs.canvas
      const image = this.$refs.image
      const ctx = canvas.getContext('2d')

      // 先绘制图片
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

      // 选中材料则填充材料
      if (this.activePartImage) {
        const fillImage = new Image()
        fillImage.src = this.activePartImage

        fillImage.onload = () => {
          ctx.save()
          // 处理路径，高亮显示选中的区域
          ctx.beginPath()
          this.paths.forEach((path) => {
            path.forEach((point, index) => {
              if (index === 0) {
                ctx.moveTo(
                  point.x * this.canvasScale,
                  point.y * this.canvasScale
                )
              } else {
                ctx.lineTo(
                  point.x * this.canvasScale,
                  point.y * this.canvasScale
                )
              }
            })
          })
          ctx.closePath()
          ctx.clip()
          const pattern = ctx.createPattern(fillImage, 'repeat')
          ctx.fillStyle = pattern
          ctx.fill()
          ctx.restore()
        }
      } else {
        // 调整选区的色相、对比度、饱和度、色温、高光、阴影、亮度
        ctx.save()

        // 创建 Path2D 对象
        const paths2D = this.paths.map((path) => {
          const path2D = new Path2D()
          path.forEach((point, index) => {
            if (index === 0) {
              path2D.moveTo(
                point.x * this.canvasScale,
                point.y * this.canvasScale
              )
            } else {
              path2D.lineTo(
                point.x * this.canvasScale,
                point.y * this.canvasScale
              )
            }
          })
          path2D.closePath()
          return path2D
        })

        // 获取选区的图像数据
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const data = imageData.data

        // 调整参数
        const hue = this.sexiang // 色相调整值（0-360）
        const saturation = this.baohedu // 饱和度调整值（-100到100）
        const brightness = this.liangdu // 亮度调整值（-100到100）
        const contrast = this.duibidu // 对比度调整值（-100到100）
        const highlights = this.gaoguang // 高光调整值（-100到100）
        const shadows = this.yinying // 阴影调整值（-100到100）
        const temperature = this.sewen // 色温调整值（-100到100）

        for (let i = 0; i < data.length; i += 4) {
          const x = (i / 4) % canvas.width
          const y = Math.floor(i / 4 / canvas.width)

          // 检查像素是否在选区内
          const isInPath = this.isPointInPath(x, y, paths2D, ctx)
          if (isInPath) {
            const r = data[i]
            const g = data[i + 1]
            const b = data[i + 2]

            // 调整色相
            const [h, s, v] = this.rgbToHsv(r, g, b)
            const newH = (h + hue) % 360
            const [newR, newG, newB] = this.hsvToRgb(newH, s, v)

            // 调整饱和度
            const newS = Math.min(100, Math.max(0, s + saturation))
            const [newR2, newG2, newB2] = this.hsvToRgb(newH, newS, v)

            // 调整亮度
            const newV = Math.min(100, Math.max(0, v + brightness))
            const [newR3, newG3, newB3] = this.hsvToRgb(newH, newS, newV)

            // 调整对比度
            const newR4 = Math.min(
              255,
              Math.max(0, (newR3 - 128) * (contrast / 100) + 128)
            )
            const newG4 = Math.min(
              255,
              Math.max(0, (newG3 - 128) * (contrast / 100) + 128)
            )
            const newB4 = Math.min(
              255,
              Math.max(0, (newB3 - 128) * (contrast / 100) + 128)
            )

            // 调整高光和阴影
            const newR5 = this.applyHighlightsAndShadows(
              newR4,
              highlights,
              shadows
            )
            const newG5 = this.applyHighlightsAndShadows(
              newG4,
              highlights,
              shadows
            )
            const newB5 = this.applyHighlightsAndShadows(
              newB4,
              highlights,
              shadows
            )

            // 调整色温
            const [finalR, finalG, finalB] = this.adjustTemperature(
              newR5,
              newG5,
              newB5,
              temperature
            )

            // 确保颜色值在 0-255 范围内
            data[i] = Math.min(255, Math.max(0, finalR))
            data[i + 1] = Math.min(255, Math.max(0, finalG))
            data[i + 2] = Math.min(255, Math.max(0, finalB))
          }
        }

        // 将修改后的图像数据放回 Canvas
        ctx.putImageData(imageData, 0, 0)

        ctx.restore()
      }
    },

    // 检查点是否在路径内
    isPointInPath(x, y, paths2D, ctx) {
      for (const path2D of paths2D) {
        if (ctx.isPointInPath(path2D, x, y)) {
          return true
        }
      }
      return false
    },
    // RGB to HSV conversion
    rgbToHsv(r, g, b) {
      r /= 255
      g /= 255
      b /= 255

      const max = Math.max(r, g, b)
      const min = Math.min(r, g, b)
      let h,
        s,
        v = max

      const d = max - min
      s = max === 0 ? 0 : d / max

      if (max === min) {
        h = 0 // achromatic
      } else {
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break
          case g:
            h = (b - r) / d + 2
            break
          case b:
            h = (r - g) / d + 4
            break
        }
        h /= 6
      }

      return [h * 360, s * 100, v * 100]
    },

    // HSV to RGB conversion
    hsvToRgb(h, s, v) {
      h /= 360
      s /= 100
      v /= 100

      const i = Math.floor(h * 6)
      const f = h * 6 - i
      const p = v * (1 - s)
      const q = v * (1 - f * s)
      const t = v * (1 - (1 - f) * s)

      let r, g, b
      switch (i % 6) {
        case 0:
          ;(r = v), (g = t), (b = p)
          break
        case 1:
          ;(r = q), (g = v), (b = p)
          break
        case 2:
          ;(r = p), (g = v), (b = t)
          break
        case 3:
          ;(r = p), (g = q), (b = v)
          break
        case 4:
          ;(r = t), (g = p), (b = v)
          break
        case 5:
          ;(r = v), (g = p), (b = q)
          break
      }

      return [r * 255, g * 255, b * 255]
    },

    // Apply highlights and shadows
    applyHighlightsAndShadows(value, highlights, shadows) {
      if (value > 128) {
        return Math.min(255, value + (255 - value) * (highlights / 100))
      } else {
        return Math.max(0, value + value * (shadows / 100))
      }
    },

    // Adjust temperature
    adjustTemperature(r, g, b, temperature) {
      if (temperature > 0) {
        r = Math.min(255, r + (255 - r) * (temperature / 100))
        g = Math.min(255, g + (255 - g) * (temperature / 200))
      } else {
        r = Math.max(0, r + r * (temperature / 100))
        g = Math.max(0, g + g * (temperature / 200))
      }
      return [r, g, b]
    }
  },

  mounted() {
    if (!this.id) {
      this.$message.error('请从创作页面进入')
      this.$router.go(-1)
      return
    }
    //this.getCategory()
    this.getPartData()
    this.getMaterial()
  }
}
</script>

    <style lang="scss" scoped>
::v-deep .el-loading-spinner {
  display: flex;
  justify-content: center;
}
.rotate {
  ::v-deep .el-input {
    .el-input__inner {
      background-color: #323232 !important;
      color: #ffffff !important;
      width: 50px !important;
      height: 30px !important;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
}
::v-deep .el-select .el-input__inner {
  width: 150px;
  background-color: #f4f5f7;
  height: 32px; /* 调整高度 */
}
.custom-rotate-input {
  ::v-deep .el-input__inner {
    background-color: #323232 !important;
    color: #ffffff !important;
    width: 50px !important;
    border: 0;
    height: 30px !important;
    margin: 0;
    padding: 0;
  }
}
.activeClass {
  position: relative;
  &::before {
    content: '';
    width: 10px;
    height: 2px;
    position: absolute;
    background: #54c752;
    bottom: 0px;
    right: 0;
  }
}
.el-slider {
  width: 250px;
  ::v-deep .el-slider__bar {
    background-color: #54c752 !important;
  }
  ::v-deep .el-slider__button {
    border: 2px solid #54c752;
  }
}
</style>
