<template>
  <div>
    <layout
      leftContentName="参数设置"
      @generator="generator"
      @revocation="revocation"
      @regenerator="regenerator"
      @save="save"
      @update="update"
      :couponInfo="couponInfo"
      :result="taskInfo"
      :isWait="isWait"
      type="imagegeneration"
    >
      <template slot="firstTag">
        <div class="p-4" v-loading="fileLoading">
          <div class="flex flex-col items-start">
            <div>导入图片</div>
            <div
              class="w-full mt-2 h-32 bg-[#444444] rounded-xl flex justify-start items-center px-2"
            >
              <div
                class="w-fit flex justify-start items-start"
                v-if="imageLists.length > 0"
              >
                <div
                  class="w-28 h-28 rounded-lg relative mr-3"
                  v-for="(item, index) in imageLists"
                  :key="index"
                >
                  <div
                    class="w-5 h-5 rounded-lg absolute top-2 right-2"
                    @click="deleteImages(index)"
                  >
                    <img src="@/assets/delete.svg" />
                  </div>
                  <div>
                    <img :src="item.url" class="w-28 h-28 rounded-lg" alt="" />
                  </div>
                </div>
              </div>
              <el-upload
                v-if="imageLists.length < 3"
                action="https://www.sheji-ai.cc/systemApi/files-anon"
                :on-success="handleFileUpload"
                multiple
                :limit="3"
                :before-upload="checkFile"
                :show-file-list="false"
                :size="'40%'"
              >
                <div
                  class="w-28 h-28 rounded-lg flex justify-center items-center bg-[#F4F5F7]"
                >
                  <img
                    src="@/assets/photo.svg"
                    alt=""
                    v-if="imageLists.length === 0"
                  />
                  <i class="el-icon-plus text-black text-2xl" v-else></i>
                </div>
              </el-upload>
              <div v-if="imageLists.length === 0" class="text-[#C4C4C4] ml-3">
                <div>在此上传（2-3张）图片进行生成</div>
                <div class="text-xs">支持图片格式：JPG,PNG, Max：10MB</div>
              </div>
            </div>
            <div class="w-full">
              <div
                class="flex"
                v-for="(item, index) in imageLists"
                :key="index"
              >
                <div class="w-[100px] text-sm text-[#8F8F8F] font-normal mt-2">
                  图{{ index + 1 }}权重
                </div>
                <div class="w-full">
                  <el-slider v-model="item.weight"></el-slider>
                </div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="flex flex-col items-start">
            <div>禁止生成（选填）</div>
            <div class="w-full mt-2">
              <ElInput
                v-model="reversePrompt"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你不想要AI生成的内容，支持中英文。"
                clearable
              ></ElInput>
            </div>
          </div>
          <el-divider></el-divider>
          <basicSetting @update="updateSetting" />
        </div>
      </template>
    </layout>
  </div>
</template>

  <script>
import { createJob } from '@/api/workbench'
import layout from '../components/layout'
import basicSetting from '../components/basicSetting'
import mixins from '../mixin'
export default {
  mixins: [mixins],
  methods: {
    /** 生成 */
    async generator() {
      if (this.imageLists.length === 0) {
        this.$message.error('请导入图片！')
        return
      }
      const params = {
        taskType: 3,
        promptCn: this.promptCn,
        reversePrompt: this.reversePrompt,
        aspectRatio: this.ratio,
        modelTheme: this.activeModel.id,
        isFast: this.mode,
        referenceImgUrl:
          this.imageLists.length > 0
            ? this.imageLists
                .map((item) => `${item.url}::${(item.weight * 2) / 100}`)
                .join(',')
            : ''
      }
      this.isWait = true
      const result = await createJob(params)
      if (!result) {
        this.isWait = false
      }
      // 开启轮询
      if (result.data) {
        this.taskId = result.data
        this.getDetail(result.data)
      }
    },
    // 处理图片上传
    handleFileUpload(val) {
      this.$message.success('上传成功')
      this.imageLists.push({ ...val, weight: 50 })
      this.fileLoading = false
    },
    // 清除图片
    deleteImages(e) {
      this.imageLists.splice(e, 1)
    }
  },
  components: {
    layout,
    basicSetting
  }
}
</script>

  <style lang="scss" scoped>
::v-deep .el-textarea__inner {
  border: 0;
  background: #f4f5f7;
}
::v-deep .el-textarea .el-input__count {
  color: #000;
  background: #f4f5f7;
}
::v-deep .el-slider__bar {
  background: #54c752;
}
::v-deep .el-slider__button {
  border-color: #54c752;
}
</style>
