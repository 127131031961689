<template>
  <div class="flex w-full overflow-hidden">
    <div class="w-full flex flex-col relative">
      <workbenchHeader
        @revocation="$emit('revocation')"
        :result="result"
        :index="activeIndex"
      ></workbenchHeader>
      <slot name="content" v-if="mode"></slot>
      <WorkbenchContent
        v-else
        @generator="$emit('generator')"
        @revocation="$emit('revocation')"
        @regenerator="$emit('regenerator')"
        @backedit="$emit('backedit')"
        @save="$emit('save')"
        @update="(val) => $emit('update', val)"
        @updateIndex="(val) => (activeIndex = val)"
        @modify="handleModify"
        @part="handlePart"
        @color="handleColor"
        :isWait="isWait"
        :couponInfo="couponInfo"
        :result="result"
        :mode="mode"
        :type="type"
      ></WorkbenchContent>
    </div>
    <div
      class="w-[550px] bg-white overflow-y-auto"
      style="height: calc(100vh - 78px)"
    >
      <rightAside :leftContentName="leftContentName" :type="type">
        <template slot="firstTag">
          <slot name="firstTag"></slot>
        </template>
      </rightAside>
    </div>
  </div>
</template>

<script>
import WorkbenchHeader from '../workbenchHeader'
import rightAside from '../rightAside'
import WorkbenchContent from '../content'
export default {
  props: {
    leftContentName: {
      type: String,
      default: ''
    },
    isWait: {
      type: Boolean,
      default: false
    },
    couponInfo: {
      type: Object,
      default: () => {}
    },
    result: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: '' | 'local' | 'access' | 'color',
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  methods: {
    handleModify(val) {
      this.$router.push({
        path: `/workbench/regenerator/${val?.id}/${val?.index}/${val?.mode}`,
        query: {
          imgUrl: val?.imgUrl
        }
      })
    },
    handlePart(val) {
      this.$router.push({
        path: `/workbench/part/${val?.id}/${val?.itemId}/${val?.index}`,
        query: {
          imgUrl: val?.imgUrl,
          type: this.type
        }
      })
    },
    handleColor(val) {
      this.$router.push({
        path: `/workbench/color/${val?.id}/${val?.itemId}/${val?.index}`,
        query: {
          imgUrl: val?.imgUrl,
          type: this.type
        }
      })
    }
  },
  components: {
    WorkbenchHeader,
    rightAside,
    WorkbenchContent
  }
}
</script>

<style>
</style>